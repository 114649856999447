.plansTela {
    width: 100%;
    height: 80vh;
    background-color: #04402F;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 3%;
    padding-top: 20vh;
    overflow: hidden;
}

.divVoltar{
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    position: fixed;
    width: 7vw;
    height: auto;
    cursor: pointer;
    color: #F2F2F2;
    top: 6vh;
    left: 2vw;
}

.divVoltar img{
    width: 2vw;
}

@media screen and (max-width: 1000px) {

    .plansTela {
        width: 100%;
        height: 76vh;
        background-color: #04402F;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding-top: 3%;
        padding-top: 20vh;
        overflow: hidden;
    }

.Planos{
    background-color: #04402F;
}
    .divVoltar{
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        align-items: center;
        position: fixed;
        width: 26vw;
        height: auto;
        cursor: pointer;
        color: #F2F2F2;
        top: 10vh;
        left: 4vw;
    }
    
    .divVoltar img{
        width: 7vw;
    }

    .divVoltar span{
        font-size: 3em;
    }

}