.FeedEmpresa{
  background-color: #F2F2F2;
  width: 100%;
  height: 100vh;
}

.FiltroFeed {
  border-top: 1px solid #cacaca;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.titleFilter {
  font-size: 1.4em;
  font-weight: 600;
  margin-top: 8vh;
  margin-bottom: 2vh;
  color: #04402f;
}

.filterOptions {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  width: 100%;
  height: 40vh;
  background-color: #04402f;
}

.imgFilter1 {
  background-image: url(../../assets/images/1.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 14vw;
  height: 28vh;
  border-radius: 100px;
  margin-bottom: 2vh;
}

.imgFilter2 {
  background-image: url(../../assets/images/2.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 14vw;
  height: 28vh;
  border-radius: 100px;
  margin-bottom: 2vh;
}

.imgFilter3 {
  background-image: url(../../assets/images/3.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 14vw;
  height: 28vh;
  border-radius: 100px;
  margin-bottom: 2vh;
}

.imgFilter4 {
  background-image: url(../../assets/images/4.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 14vw;
  height: 28vh;
  border-radius: 100px;
  margin-bottom: 2vh;
}

.imgFilter5 {
  background-image: url(../../assets/images/5.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 14vw;
  height: 28vh;
  border-radius: 100px;
  margin-bottom: 2vh;
}

.cont {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}

.contDivCadPub {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 50vh;
  background-color: #f2f2f2;
}

.divCadPub {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  height: 16vh;
  width: 52vw;
  text-align: center;
  font-size: 1em;
  color: #04402f;
  margin-left: 34vw;
  background-color: #ffffff;
  border-radius: 8px;
}

.borderBotton {
  width: 56vw;
  height: 3vh;
  margin-left: 34vw;
  margin-bottom: 4vh;
  border-bottom: 1px solid #bfbebb;
}

.inputNewPub {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2vw;
  width: 80%;
  height: 35%;
  color: #9b9b9b;
  font-weight: 600;
  background: #ebebeb;
  border: 0.5px solid #9b9b9b;
  border-radius: 1000px;
  cursor: pointer;
}

/* -------------------------------- */
/*           CARD NEW PUB           */

.ExibePubEmp {
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 60vh;
}

body.modal-open {
  overflow: hidden;
}

.containerEmp {
  background-color: #f2f2f2;
  padding: 3%;
  display: flex;
  flex-direction: column;
  width: 54vw;
  height: 44vh;
  margin-left: 34vw;
  box-shadow: 4px 4px 10px #0000001a;
  border-radius: 8px;
}

.contOngEmp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
  width: 100%;
}

.OngEmp {
  font-size: 1.6em;
  font-weight: 700;
}

.heartEmp {
  width: 4vw;
}

.btnHeartEmp {
  background-color: none;
  border: none;
}

.imgFavoriteEmp {
  background-color: none;
  width: 6vh;
  cursor: pointer;
}

.titlePubEmp {
  width: 100%;
  height: 10vh;
  display: flex;
  flex-direction: column-reverse;
  font-size: 1.7em;
  font-weight: 700;
  margin-bottom: 2vh;
}

.contTextPubEmp {
  width: 100%;
  height: 20vh;
  font-size: 1.2em;
  padding-top: 3vh;
  overflow: auto;
}

.footerPubEmp {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.datePubEmp {
  margin-top: 4vh;
  font-size: 1.2em;
}

/* -------------------------------- */
/*           MODAL CRIAR PUB        */

.CriarPub {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
}

.backgroundShadow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #00000033;
}

.divBtnClose {
  display: flex;
  justify-content: flex-end;
}

.btnClose {
  width: 4vh;
}



.NewPost {
  width: 86%;
  height: 84vh;
  padding: 2%;
  border-radius: 16px;
  background-color: #ffffff;
}

.formNewPost {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
}

.boxForm1 {
  width: 30vw;
  height: 80vh;
}

.comboBoxForm {
  width: 20vw;
  height: 6vh;
  resize: none;
  padding-left: 2%;
  margin-bottom: 2vh;
  border: 0.6px solid #7CA68E;
  border-radius: 8px;
  background-color: #ffffff;
}

.optComboBox {
  width: 20vw;
  height: 8vh;
  padding: 2%;
  background-color: #f2f2f2;
  font-family: 'Oxygen', sans-serif;
  font-size: 1.1em;
}

.comboBox2 {
  display: flex;
  flex-direction: column;
  margin-top: 3vh;
}

.optionsPub {
  display: flex;
  flex-direction: column;
}

.boxForm2 {
  width: 26vw;
  height: 80vh;
}

.boxForm3 {
  width: 24vw;
  height: 80vh;
}

.comboBox3 {
  display: flex;
  flex-direction: column;

}

.titulo {
  font-weight: 700;
  font-size: 1.7em;
  color: #04402F;
  margin-left: 5vh;
}

.titulo2 {
  font-weight: 700;
  font-size: 1.7em;
  color: #04402F;
}

.titulo3 {
  font-weight: 700;
  font-size: 1.7em;
  color: #04402F;
}

.tituloBneficio {
  font-weight: 700;
  font-size: 1.7em;
  color: #04402F;
  margin-left: 5vh;
}

.selector {
  display: grid;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 5vh;
}

.horas-trabalho {
  justify-content: flex-start;
  margin-top: 2vh;
  margin-left: 5vh;
}

.infoTitelPub {
  font-weight: 400;
  font-size: 1.0em;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.opcoes-beneficios {
  justify-content: flex-start;
  margin-top: 1.2em;
  font-size: 1.0em;
  font-weight: bold;
  margin-left: 5vh;
  font-weight: 400;
}

.infoEvento {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 1vh;
  font-size: 1.0em;
  font-weight: 400;
}

.inputDate {
  width: 20vw;
  height: 5vh;
  border: 0.6px solid #7CA68E;
  border-radius: 8px;
  padding-left: 2%;
  font-family: 'Oxygen', sans-serif;
}

.infoTrabalho {
  width: 20vw;
  height: 5vh;
  border: 0.6px solid #7CA68E;
  border-radius: 8px;
  background-color: #ffffff;
  text-indent: 5%;
  margin-bottom: 1vh;
}

.btnPublicar {
  width: 21vw;
  height: 6vh;
  border: none;
  border-radius: 12px;
  font-size: 1.2em;
  background: #04402F;
  color: #ffffff;
  cursor: pointer;
  margin-top: 3vh;
}



/* -------------------------------- */
/*           MODAL SUCESSO          */
.backgroundShadowModalSucesso {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #00000033;
}

.modalSucesso {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  position: absolute;
  width: 38vw;
  height: 42vh;
  left: 551px;
  z-index: 9999;
  top: 410px;
  background: #ffffff;
  box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

.TextModalSucesso {
  font-size: 1.4em;
  font-weight: bold;
}

.btnCloseModalSucesso {
  width: 12vw;
  height: 6vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #04402F;
  border: none;
  border-radius: 10px;
  padding: 1%;
  color: #F2F2F2;
  font-size: 1.1em;
  cursor: pointer;
}

.info-evento {
  display: flex;
  flex-direction: column;
}

.txtArea {
  padding-left: 2%;
  width: 19vw;
  height: 30vh;
  resize: none;
  border: 0.6px solid #7CA68E;
  border-radius: 8px;
  background-color: #ffffff;
  text-indent: 5%;
  padding: 3%;
  font-family: 'Oxygen', sans-serif;
  font-size: 0.9em;
}

/* -------------------------------- */
/*              Toggle              */

.toggleBtn {
  transition: 0.5s;
  height: 7vh;
  font-size: 1.2;
  font-weight: bold;
  border-radius: 20px;
}